import React, {
  useState, useEffect, useCallback,
} from 'react';
import {
  Row, Col, Input, Button, Form, Typography, Divider, Card,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import Ckeditor from '../../../shared/Ckeditor';
import {
  fieldValidate,
} from '../../../shared/function';
import { onGetPageContent, onUpdatePageContent } from '../saga';

const { Title } = Typography;

const Edit = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [fields, setFields] = useState([]);

  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const callback = (status, response) => {
    if (status === 200) {
      setState(response);
    }

    setSubmiting(false);
  };

  const getData = useCallback((params) => dispatch(
    onGetPageContent(params, setState),
  ), [dispatch]);

  const updateData = useCallback((params) => dispatch(
    onUpdatePageContent(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);

    const params = (values.title || []).map((title, index) => ({
      title: title || '',
      description: values.description[index] || '',
    }));

    updateData({ page_content: { content: params }, id: state.id });
  };

  const addNew = () => {
    setFields((state) => state.concat([{}]));
  };

  const removeItem = (index) => {
    const currentFields = fields;
    currentFields.splice(index, 1);
    setFields([].concat(currentFields));
    form.resetFields([['title', index]]);
  };

  useEffect(() => {
    getData({ page: 'our_firm' });
  }, []);

  useEffect(() => {
    setFields(state.content || []);
    if (state.content && state.content.length > 0) {
      const title = [];
      const description = [];
      state.content.map((data, index) => {
        title.push(data.title);
        description.push(data.description);
      });

      form.setFieldsValue({
        title,
        description,
      });
    }
  }, [state]);

  return (
    <Form
      layout='vertical'
      form={form}
      scrollToFirstError
      onFinish={onFinish}
    >
      <Row>
        <Col offset={4} span={16} style={{ marginBottom: 20 }}>
          <Title level={2} style={{ textAlign: 'center' }}>Our Firm Page</Title>
        </Col>
        <Col offset={4} span={16} style={{ marginBottom: 20 }}>
          <Button onClick={addNew}>
            Add Content
            {' '}
            <PlusOutlined />
          </Button>
          <Button
            disabled={submiting}
            type='primary'
            htmlType='submit'
            style={{ width: 150, marginLeft: 20 }}
          >
            Save
          </Button>
        </Col>

        { fields.map((item, index) => (
          <Col offset={4} span={16} key={index}>
            <Card
              actions={[
                <Button type='danger' icon={<DeleteOutlined />} size='small' onClick={() => removeItem(index)}>
                  Delete
                </Button>,
              ]}
            >

              <Form.Item
                name={['title', index]}
              >
                <Input placeholder='Title' />
              </Form.Item>
              <Form.Item
                name={['description', index]}
              >
                <Ckeditor
                  onChange={(value) => {
                    const currentField = form.getFieldValue('description') || [];
                    currentField[index] = value;
                    form.setFieldsValue({ description: currentField });
                  }}
                  defaultValue={item.description || ''}
                />
              </Form.Item>
            </Card>
            <Divider />
          </Col>
        )) }

        { fields.length > 0 && (
        <Col offset={4} span={16} style={{ marginBottom: 20 }}>
          <Button onClick={addNew}>
            Add Content
            {' '}
            <PlusOutlined />
          </Button>
          <Button
            disabled={submiting}
            type='primary'
            htmlType='submit'
            style={{ width: 150, marginLeft: 20 }}
          >
            Save
          </Button>
        </Col>
        ) }
      </Row>
    </Form>
  );
};

export default Edit;
