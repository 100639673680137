import React, {
  useState, useEffect, useCallback,
} from 'react';
import {
  Row, Col, Tabs, Button, Form, Typography, Card, Divider, Input, Descriptions, InputNumber,
} from 'antd';
import { useDispatch } from 'react-redux';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import Ckeditor from '../../../shared/Ckeditor';
import { onGetPageContent, onUpdatePageContent } from '../saga';
import Upload from '../../../components/Upload';

const { TabPane } = Tabs;
const { Title } = Typography;

const Page = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [fields, setFields] = useState([]);

  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const callback = (status, response) => {
    setSubmiting(false);
  };

  const addNew = () => {
    setFields((state) => state.concat([{}]));
  };

  const removeItem = (index) => {
    const currentFields = fields;
    currentFields.splice(index, 1);
    setFields([].concat(currentFields));
    form.resetFields([['banner_name', index]]);
  };

  const getData = useCallback((params) => dispatch(
    onGetPageContent(params, setState),
  ), [dispatch]);

  const updateData = useCallback((params) => dispatch(
    onUpdatePageContent(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    const params = [{
      key: 'banner',
      value: (values.image || []).map((item, index) => (
        {
          name: (values.banner_name || [])[index] || '',
          url: item,
        }
      )),
    },{
      key: 'banner_title',
      value: values.banner_title,
    }, {
      key: 'investment',
      title: values.investment_title ? values.investment_title : state.investment.title || '',
      sub_title: values.investment_sub_title ? values.investment_sub_title : state.investment.sub_title || '',
      content_title: values.investment_content_title ? values.investment_content_title : state.investment.content_title || '',
      content_sub_title: values.investment_content_sub_title ? values.investment_content_sub_title : state.investment.content_sub_title || '',
      content: values.content ? values.content : state.investment.content || '',
    }, {
      key: 'expertise',
      title: values.expertise_title ? values.expertise_title : state.expertise.title || '',
      number_1: values.expertise_number_1 ? values.expertise_number_1 : state.expertise.number_1 || '',
      title_1: values.expertise_title_1 ? values.expertise_title_1 : state.expertise.title_1 || '',
      description_1: values.expertise_description_1 ? values.expertise_description_1 : state.expertise.description_1 || '',
      number_2: values.expertise_number_2 ? values.expertise_number_2 : state.expertise.number_2 || '',
      title_2: values.expertise_title_2 ? values.expertise_title_2 : state.expertise.title_2 || '',
      description_2: values.expertise_description_2 ? values.expertise_description_2 : state.expertise.description_2 || '',
      number_3: values.expertise_number_3 ? values.expertise_number_3 : state.expertise.number_3 || '',
      title_3: values.expertise_title_3 ? values.expertise_title_3 : state.expertise.title_3 || '',
      description_3: values.expertise_description_3 ? values.expertise_description_3 : state.expertise.description_3 || '',
    }];

    updateData({ page_content: { content: params }, id: state.id });
  };

  useEffect(() => {
    getData({ page: 'home' });
  }, []);

  useEffect(() => {
    if (state.banner) {
      setFields(state.banner || []);
      const banner_name = [];
      const image = [];
      state.banner.map((data, index) => {
        banner_name.push(data.name);
        image.push(data.url);
      });

      form.setFieldsValue({
        banner_name,
        image,
      });
    }

    if (state.investment) {
      form.setFieldsValue({
        investment_title: state.investment.title,
        investment_sub_title: state.investment.sub_title,
        investment_content_title: state.investment.content_title,
        investment_content_sub_title: state.investment.content_sub_title,
        content: state.investment.content,
      });
    }

    if (state.banner_title) {
      form.setFieldsValue({
        banner_title: state.banner_title,
      });
    }


    if (state.expertise) {
      form.setFieldsValue({
        expertise_title: state.expertise.title,
        expertise_number_1: state.expertise.number_1,
        expertise_title_1: state.expertise.title_1,
        expertise_description_1: state.expertise.description_1,
        expertise_number_2: state.expertise.number_2,
        expertise_title_2: state.expertise.title_2,
        expertise_description_2: state.expertise.description_2,
        expertise_number_3: state.expertise.number_3,
        expertise_title_3: state.expertise.title_3,
        expertise_description_3: state.expertise.description_3,
      });
    }
  }, [state]);

  return (
    <Form
      layout='vertical'
      form={form}
      scrollToFirstError
      onFinish={onFinish}
    >
      <Row>
        <Col offset={4} span={16} style={{ marginBottom: 20 }}>
          <Title level={2} style={{ textAlign: 'center' }}>Home Page Content</Title>
        </Col>
        <Col offset={4} span={16} style={{ marginBottom: 20 }}>
          <Tabs defaultActiveKey='1' onChange={callback}>
            <TabPane tab='Banner' key='1'>
              <Form.Item
                label='Banner Title'
                name='banner_title'
              >
                <Input placeholder='Banner Title' />
              </Form.Item>
              <Button onClick={addNew}>
                Add Banner
                {' '}
                <PlusOutlined />
              </Button>
              { fields.map((item, index) => (
                <Col span={24} key={index}>
                  <Card
                    actions={[
                      <Button type='danger' icon={<DeleteOutlined />} size='small' onClick={() => removeItem(index)}>
                        Delete
                      </Button>,
                    ]}
                  >
                    <Form.Item
                      name={['banner_name', index]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={['image', index]}
                    >
                      <Upload
                        defaultValue={item.url || ''}
                        uploadUrl='/direct_upload'
                        cropImage
                        cropImageProps={{
                          rotate: true, aspect: 1.535,
                        }}
                        onFileChange={(fileUrl) => {
                          const currentField = form.getFieldValue('image') || [];
                          currentField[index] = fileUrl;
                          form.setFieldsValue({ image: currentField });
                        }}
                      />
                    </Form.Item>
                  </Card>
                  <Divider />
                </Col>
              )) }
            </TabPane>
            <TabPane tab='Investments & Funds' key='2'>
              <Card>
                <Form.Item
                  name='investment_title'
                >
                  <Input placeholder='Block Title' />
                </Form.Item>
                <Form.Item
                  name='investment_sub_title'
                >
                  <Input placeholder='Block  Sub Title' />
                </Form.Item>
                <Form.Item
                  name='investment_content_title'
                >
                  <Input placeholder='Content Title' />
                </Form.Item>
                <Form.Item
                  name='investment_content_sub_title'
                >
                  <Input placeholder='Content Sub Title' />
                </Form.Item>
                <Form.Item
                  name='content'
                >
                  <Ckeditor
                    onChange={(value) => {
                      form.setFieldsValue({ content: value });
                    }}
                    defaultValue={state.investment ? state.investment.content || '' : ''}
                  />
                </Form.Item>
              </Card>
            </TabPane>
            <TabPane tab='Our Expertise' key='3'>
              <Card>
                <Form.Item
                  name='expertise_title'
                >
                  <Input placeholder='Block Title' />
                </Form.Item>
                <Descriptions
                  bordered
                  layout='vertical'
                  column={3}
                >
                  <Descriptions.Item label='First Content'>
                    <Form.Item
                      name='expertise_number_1'
                    >
                      <InputNumber placeholder='Number' />
                    </Form.Item>
                    <Form.Item
                      name='expertise_title_1'
                    >
                      <Input placeholder='Title' />
                    </Form.Item>
                    <Form.Item
                      name='expertise_description_1'
                    >
                      <Input placeholder='Description' />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label='Second Content'>
                    <Form.Item
                      name='expertise_number_2'
                    >
                      <InputNumber placeholder='Number' />
                    </Form.Item>
                    <Form.Item
                      name='expertise_title_2'
                    >
                      <Input placeholder='Title' />
                    </Form.Item>
                    <Form.Item
                      name='expertise_description_2'
                    >
                      <Input placeholder='Description' />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label='Third Content'>
                    <Form.Item
                      name='expertise_number_3'
                    >
                      <InputNumber placeholder='Number' />
                    </Form.Item>
                    <Form.Item
                      name='expertise_title_3'
                    >
                      <Input placeholder='Title' />
                    </Form.Item>
                    <Form.Item
                      name='expertise_description_3'
                    >
                      <Input placeholder='Description' />
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Row justify='center'>
        <Button
          disabled={submiting}
          type='primary'
          size='large'
          htmlType='submit'
          style={{ width: 150, marginRight: 20 }}
        >
          Save
        </Button>
      </Row>
    </Form>
  );
};

export default Page;
