import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Button, Select, DatePicker, Modal,
} from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  fieldValidate,
} from '../../../shared/function';
import Upload, { Upload as UploadFile } from '../../../components/Upload';

import { onEditPress } from '../saga';

const { Option } = Select;

const Edit = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(
    onEditPress(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    const formData = new FormData();
    formData.append('press[title]', values.title);
    formData.append('press[status]', values.status);
    formData.append('press[created_at]', values.created_at.format('DD/MM/YYYY'));

    if (values.image) formData.append('press[image]', values.image);
    if (values.file) formData.append('press[file]', values.file);

    editData({ id: item.id, formData });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        title: item.title,
        status: item.status,
        created_at: moment(item.createdAt, 'DD/MM/YYYY'),
      });
      setState({ status: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      title={`Edit Press: ${item.title || ''}`}
    >
      <Form
        layout='vertical'
        form={form}
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              label='Image'
              name='image'
            >
              <Upload
                defaultValue={item.image}
                cropImage
                cropImageProps={{
                  rotate: true, aspect: 6 / 7,
                }}
                onFileChange={(file) => form.setFieldsValue({ image: file })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='title'
              label='Title'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='title' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='created_at'
              label='Create Date'
              rules={[fieldValidate.required]}
            >
              <DatePicker format='DD/MM/YYYY' style={{ width: '100%' }} placeholder='Create Date' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[fieldValidate.required]}
              name='status'
              label='Status'
            >
              <Select placeholder='Select a option'>
                <Option value='publish'>Publish</Option>
                <Option value='draft'>Draft</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label='File'
              name='file'
            >
              <UploadFile
                defaultValue={item.file}
                isDocument
                onFileChange={(file) => form.setFieldsValue({ file })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Save
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Close
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
