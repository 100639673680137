import React from 'react';
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from '../config/store';
import { history } from '../vendor/history';

import Login from '../pages/Login';

import Portfolio from '../pages/Portfolio';
import OurLeaders from '../pages/OurLeaders';
import OurFirm from '../pages/PageContent/OurFirm';
import OurValues from '../pages/PageContent/OurValues';
import OurCompetencies from '../pages/PageContent/OurCompetencies';
import OurImpacts from '../pages/PageContent/OurImpacts';
import OurBusiness from '../pages/PageContent/OurBusiness';
import HomePage from '../pages/PageContent/HomePage';
import ContactUs from '../pages/ContactUs';
import Media from '../pages/Media';
import Press from '../pages/Press';
import Error from '../pages/Error';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import GlobalStyle from './styles';

const store = configureStore();

export default () => (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <PublicRoute exact path='/user/sign_in' component={Login} />

        <PrivateRoute exact path='/portfolio' component={Portfolio} />
        <PrivateRoute exact path='/contact-us' component={ContactUs} />
        <PrivateRoute exact path='/media' component={Media} />
        <PrivateRoute exact path='/presses' component={Press} />
        <PrivateRoute exact path='/our-leaders' component={OurLeaders} />
        <PrivateRoute exact path='/our-firm' component={OurFirm} />
        <PrivateRoute exact path='/our-values' component={OurValues} />
        <PrivateRoute exact path='/our-competencies' component={OurCompetencies} />
        <PrivateRoute exact path='/our-impacts' component={OurImpacts} />
        <PrivateRoute exact path='/our-business' component={OurBusiness} />
        <PrivateRoute exact path='/home-page-content' component={HomePage} />
        <Route
          exact
          path='/error/500'
          component={() => <Error status='500' title='500' subTitle='Sorry, something went wrong.' />}
        />
        <Route component={() => <Error status='404' title='404' subTitle='Sorry, the page you visited does not exist.' />} />
      </Switch>
      <GlobalStyle />
    </Router>
  </Provider>
);
