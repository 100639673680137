import React, { useState, useEffect } from 'react';
import { message, Upload as AntUpload, Button } from 'antd';
import {
  LoadingOutlined, PlusOutlined, UploadOutlined,
} from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { StyledUpload } from './styles';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function isImage(contentType) {
  return contentType === 'image/jpeg' || contentType === 'image/png' || contentType === 'image/gif';
}

function beforeUpload(file) {
  const isJpgOrPng = isImage(file.type);
  if (!isJpgOrPng) {
    message.error('You can only upload image file!');
  }

  return isJpgOrPng;
}

export const Upload = ({ onFileChange, defaultValue, isDocument }) => {
  const [state, setState] = useState({ loading: false, file: defaultValue });
  const { file } = state;
  const { url, type } = file || {};

  const fileList = file ? [file] : [];

  const onBeforeUpload = (file) => {
    const isImageOrPdf = isDocument || isImage(file.type) || file.type === 'application/pdf';
    if (!isImageOrPdf) {
      message.error('You can only upload image/pdf file!');
    }

    return isImageOrPdf;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setState({ file: info.file });
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (url) => {
        const newFile = { ...info.file, url };
        setState({
          file: newFile,
          loading: false,
        });
      });

      if (onFileChange) onFileChange(info.file.originFileObj);
    }
  };

  useEffect(() => {
    setState({ loading: false, file: defaultValue });
  }, [defaultValue]);

  return (
    <div>
      <AntUpload
        {...(isDocument && { accept: 'image/*, application/pdf, application/vnd.ms-excel, .doc, .docx, .xlsx, application/msword' })}
        action='/upload'
        onChange={handleChange}
        beforeUpload={onBeforeUpload}
        showUploadList={isImage(type) ? false : {
          showRemoveIcon: false,
        }}
        fileList={fileList}
      >
        <Button icon={<UploadOutlined />}>Upload</Button>
      </AntUpload>
      {url && (
        <div>
          {isImage(type) && (
            <div style={{ textAlign: 'center' }}>
              <img className='upload-image' src={url} alt='avatar' style={{ maxHeight: 450, maxWidth: '100%', marginTop: 8 }} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const UploadImage = ({
  onFileChange, defaultValue, circle, cropImage = false, cropImageProps, square, uploadUrl,
}) => {
  const [state, setState] = useState({ loading: false, imageUrl: defaultValue || '' });
  const { imageUrl, loading } = state;

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (url) => {
        setState({
          imageUrl: url,
          loading: false,
        });
      });

      if (onFileChange) {
        if (uploadUrl) {
          onFileChange(info.file.response.url);
        } else {
          onFileChange(new File([info.file.originFileObj], info.file.name));
        }
      }
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className='ant-upload-text'>Tải hình</div>
    </div>
  );

  useEffect(() => {
    setState({ loading: false, imageUrl: defaultValue });
  }, [defaultValue]);

  const upload = (
    <StyledUpload
      square={square}
      circle={circle}
      action={uploadUrl || 'upload'}
      name='upload'
      listType='picture-card'
      className='avatar-uploader'
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? <img className='upload-image' src={imageUrl} alt='avatar' style={{ width: '100%' }} /> : uploadButton}
    </StyledUpload>
  );

  if (!cropImage) return upload;

  return (
    <ImgCrop {...cropImageProps}>
      {upload}
    </ImgCrop>
  );
};

export default UploadImage;
