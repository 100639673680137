import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import {
  Row, Col, Input, Form, Select, Button,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';

const { Option } = Select;

const Filter = ({ currentFilter, changeLocation }) => {
  const pathLocation = useLocation();
  const [form] = Form.useForm();
  let typing = null;

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      changeLocation();
    }, 1000);
  };

  const onFilter = (field, value) => {
    currentFilter.current[field] = value;
    changeLocation();
  };

  const clearFilter = () => {
    currentFilter.current = {};
    form.setFieldsValue({
      keyword: '',
      type: '',
      location: '',
    });
    changeLocation();
  };

  useEffect(() => {
    const {
      keyword, type, location,
    } = queryString.parse(pathLocation.search);

    form.setFieldsValue({
      keyword: keyword || '',
      type: type || '',
      location: location || '',
    });
  }, []);

  return (
    <Form layout='vertical' form={form} style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col span={24} md={8} lg={6} xl={6} xxl={4}>
          <Form.Item
            name='keyword'
            label='Search Keyword'
          >
            <Input placeholder='input keyword...' onChange={onSearch} />
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={6} xl={6} xxl={4}>
          <Form.Item
            name='type'
            label='Type'
          >
            <Select
              placeholder='Select a option'
              onChange={(e) => onFilter('type', e)}
            >
              <Option value=''>All</Option>
              <Option value='current'>Current</Option>
              <Option value='past'>Past</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={6} xl={6} xxl={4}>
          <Form.Item
            name='location'
            label='Location'
          >
            <Select
              placeholder='Select a option'
              onChange={(e) => onFilter('location', e)}
            >
              <Option value=''>All</Option>
              <Option value='indonesia'>Indonesia</Option>
              <Option value='singapore'>Singapore</Option>
              <Option value='thailand'>Thailand</Option>
              <Option value='vietnam'>Vietnam</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={6} xl={6} xxl={4}>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type='primary' onClick={clearFilter} icon={<FilterOutlined />}>Clear Filter</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
