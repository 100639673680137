import { get } from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';

import { ON_AUTHENTICATION, onUpdateAuthtication } from './redux';

function* userAuthentication({ callback }) {
  try {
    yield call(get, '/current_user/info');

    yield put(onUpdateAuthtication(true));
    callback(200);
  } catch (error) {
    callback(error.status);
  }
}

export default function* routeWatcher() {
  yield takeLatest(ON_AUTHENTICATION, userAuthentication);
}
