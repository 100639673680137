import React from 'react';
import {
  Table, Row, Card, Col, Typography,
} from 'antd';

const { Title } = Typography;

const CustomTable = ({
  title, columns, totalPages, currentPage, onChange, dataSource, loading, filter: FilterComponent, actionButton, extra: ExtraComponent,
}) => (
  <Row>
    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Title level={4} style={{ marginBottom: 20 }}>{title}</Title>
      {actionButton || null}
    </Col>
    <Col span={24}>
      <Card>
        <Row>
          {FilterComponent && (
            <Col span={24} style={{ marginBottom: 10 }}>
              {FilterComponent}
            </Col>
          )}
          {ExtraComponent && (
            <Col span={24} style={{ marginBottom: 20 }}>
              {ExtraComponent}
            </Col>
          )}
          <Col span={24}>
            <Table
              columns={columns}
              loading={loading}
              dataSource={dataSource}
              onChange={onChange}
              pagination={{
                total: (25 * totalPages),
                position: ['', 'bottomCenter'],
                pageSize: 25,
                showLessItems: true,
                current: currentPage,
                defaultCurrent: 1,
              }}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  </Row>
);

export default CustomTable;
