import { post } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';

export const ON_LOGOUT = 'ON_LOGOUT';

function* logout() {
  try {
    yield call(post, '/current_user/info/logout');
  } catch (error) {
    console.error(error);
  }
}

export const onLogout = () => ({
  type: ON_LOGOUT,
});

export default function* publicRouteWatcher() {
  yield takeLatest(ON_LOGOUT, logout);
}
