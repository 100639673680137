import React, { useRef } from 'react';
import { Layout } from 'antd';
import Header from './Header';
import Sider from './Sider';
import Content from './Content';

const PrivateLayout = ({ children }) => {
  const siderRef = useRef();
  return (
    <Layout>
      <Sider ref={siderRef} />
      <Layout className='site-layout'>
        <Header siderRef={siderRef} />
        <Content>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default PrivateLayout;
