import styled, { css } from 'styled-components';
import { Modal } from 'antd';


export const StyledModal = styled(Modal)`
  width: 1140px !important;

  @media (max-width: 1140px) {
    width: 1024px !important;
  }
`;
