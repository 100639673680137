import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ActionWrapper } from '../styles';
import { fallbackImage } from '../../shared/function';

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    defaultSortOrder: 'descend',
    sorter: {},
  },
  {
    width: 230,
    title: 'Image',
    dataIndex: 'image',
    render: (image) => <img src={image || fallbackImage} style={{ width: 195, height: 210 }} alt='error' />,
  },
  {
    width: 200,
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: {},
  },
  {
    width: 200,
    title: 'Position',
    dataIndex: 'position',
    key: 'position',
    sorter: {},
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (description) => <div style={{ whiteSpace: 'pre-line' }}>{description}</div>,
  },
  {
    title: 'Action',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Tooltip title='Edit' color='geekblue' key='geekblue'>
          <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onEdit(item)} />
        </Tooltip>
        <Tooltip title='Delete' color='red' key='red'>
          <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onDelete(item)} />
        </Tooltip>
      </ActionWrapper>
    ),
  },
];

export default columns;
