import React from 'react';
import { Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';

const LayoutContent = ({ children }) => {
  const location = useLocation();
  return (
    <Row
      className={location.pathname === '/dashboard' ? 'site-statistic-card' : 'site-layout-background'}
      style={{
        padding: 24,
        margin: '24px 12px',
      }}
    >
      <Col span={24}>
        {children}
      </Col>
    </Row>
  );
};

export default LayoutContent;
