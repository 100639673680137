import { all } from 'redux-saga/effects';
import publicRouteWatcher from '../App/PublicRoute/saga';
import privateRouteWatcher from '../App/PrivateRoute/saga';
import loginWatcher from '../pages/Login/saga';
import portfolioWatcher from '../pages/Portfolio/saga';
import contactUsWatcher from '../pages/ContactUs/saga';
import mediaWatcher from '../pages/Media/saga';
import pressWatcher from '../pages/Press/saga';
import ourLeadersWatcher from '../pages/OurLeaders/saga';
import pageContentWatcher from '../pages/PageContent/saga';

export default function* saga() {
  yield all([
    publicRouteWatcher(),
    privateRouteWatcher(),
    loginWatcher(),
    portfolioWatcher(),
    contactUsWatcher(),
    mediaWatcher(),
    pressWatcher(),
    ourLeadersWatcher(),
    pageContentWatcher(),
  ]);
}
