import styled from 'styled-components';
import { Col, Row, Form } from 'antd';

export const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`;

export const Logo = styled.img`
  width: 300px;
  object-fit: contain;
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledRow = styled(Row)`
  width: 100%;
  height: auto;
  padding: 30px 0;
  @media (max-width: 767px) {
    height: auto;
    padding: 0 15px;
  }
`;
