import React, { useEffect } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import '../ckeditor5/build/ckeditor';

const Editor = ({ onChange, defaultValue }) => {
  useEffect(() => {
    if (defaultValue) onChange(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <CKEditor
        data={defaultValue || ''}
        config={{
          ckfinder: {
            uploadUrl: '/direct_upload',
          },
          toolbar: {
            items: [
              '|',
              'bold',
              'italic',
              'heading',
              'indent',
              'outdent',
              'numberedList',
              'bulletedList',
              'blockQuote',
              'imageUpload',
              'alignment',
              'fontColor',
              'link',
              'code',
              'fontBackgroundColor',
              'codeBlock',
              'fontFamily',
              'highlight',
              'fontSize',
              'undo',
              'redo',
            ],
          },
          language: 'en',
          image: {
            styles: [
              'alignLeft', 'alignCenter', 'alignRight',
            ],
            toolbar: [
              'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
              '|',
              'imageResize',
              '|',
              'imageTextAlternative',
            ],
          },
          table: {
            contentToolbar: [
              'tableColumn',
              'tableRow',
              'mergeTableCells',
            ],
          },
          link: {
            addTargetToExternalLinks: true,
            decorators: [
              {
                mode: 'manual',
                label: 'Downloadable',
                attributes: {
                  download: 'download',
                },
              },
            ],
          },
        }}
        editor={ ClassicEditor }
        onChange={ (event, editor) => {
          const data = editor.getData();
          if (onChange) onChange(data);
        } }
      />
    </>
  );
};

export default Editor;
