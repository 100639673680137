import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ActionWrapper } from '../styles';

export const columns = (onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    defaultSortOrder: 'descend',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: {},
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: {},
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    render: (message) => <div style={{ whiteSpace: 'pre-line' }}>{message}</div>
  },
  {
    title: 'Submit At',
    dataIndex: 'createdAt',
    key: 'created_at',
  },
  {
    title: 'Action',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Tooltip title='Delete' color='red' key='red'>
          <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onDelete(item)} />
        </Tooltip>
      </ActionWrapper>
    ),
  },
];

export default columns;
