import { post } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../components/Notification';

export const ON_LOGIN = 'ON_LOGIN';

function* authentication({ params, callback }) {
  try {
    yield call(post, '/user/sign_in', params);
    Notification.success({ message: 'Logged Successfully' });
    window.location = '/portfolio';
  } catch (error) {
    callback();
    Notification.error({ message: 'Email or Password incorrect!!' });
  }
}

export const onLogin = (params, callback) => ({
  type: ON_LOGIN, params, callback,
});

export default function* loginWatcher() {
  yield takeLatest(ON_LOGIN, authentication);
}
