import styled, { css } from 'styled-components';
import { Upload } from 'antd';

export const StyledUpload = styled(Upload)`
  display: flex;
  justify-content: center;

  .ant-upload {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;

    ${({ circle }) => (circle ? css`
      width: 160px !important;
      height: 160px !important;
      border-radius: 100% !important;
    ` : css`
        width: 100% !important;
        height: 300px !important;
    `)}

    ${({ square }) => (square && css`
      width: 160px !important;
      height: 160px !important;
    `)}
    position: relative;
    overflow: hidden;

    .upload-image {
      object-fit: contain;
      width: 100%;

      ${({ circle }) => (circle ? css`
        width: 160px !important;
        height: 160px !important;
        border-radius: 100% !important;
      ` : css`
          width: 100% !important;
          height: 300px !important;
      `)}

      ${({ square }) => (square && css`
        width: 160px !important;
        height: 160px !important;
      `)}

    }
  }
`;

export default StyledUpload;
