import {
  get, delete as axiosDelete,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../components/Notification';
import { getErrorMessage } from '../../shared/function';

const ON_GET_CONTACT_US = 'ON_GET_CONTACT_US';
const ON_REMOVE_CONTACT_US = 'ON_REMOVE_CONTACT_US';

function* getContactUs({ params, setData }) {
  try {
    const response = yield call(get, '/contact_infos', { params });

    setData({
      loading: false,
      items: response.items,
      totalPage: response.totalPage,
      currentPage: response.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* removeContactUs({ params, callback }) {
  try {
    yield call(axiosDelete, `/contact_infos/${params.id}`);
    Notification.success({ message: 'Contact has been deleted' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onGetContactUs = (params, setData) => ({
  type: ON_GET_CONTACT_US, params, setData,
});

export const onDeleteContactUs = (params, callback) => ({
  type: ON_REMOVE_CONTACT_US, params, callback,
});

export default function* contactUsWatcher() {
  yield takeLatest(ON_GET_CONTACT_US, getContactUs);
  yield takeLatest(ON_REMOVE_CONTACT_US, removeContactUs);
}
