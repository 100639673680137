import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ActionWrapper } from '../styles';
import { fallbackImage } from '../../shared/function';

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    defaultSortOrder: 'descend',
  },
  {
    width: 400,
    title: 'Image',
    dataIndex: 'image',
    render: (image) => <img src={image || fallbackImage} style={{ width: 398, height: 149 }} alt='error' />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: {},
  },
  {
    title: 'Location',
    dataIndex: 'locationText',
    key: 'location',
    sorter: {},
  },
  {
    title: 'Type',
    dataIndex: 'portfolioTypeText',
    key: 'portfolio_type',
    sorter: {},
  },
  {
    title: 'Short Description',
    dataIndex: 'shortDescription',
    key: 'short_description',
  },
  {
    title: 'Action',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Tooltip title='Edit' color='geekblue' key='geekblue'>
          <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onEdit(item)} />
        </Tooltip>
        <Tooltip title='Delete' color='red' key='red'>
          <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onDelete(item)} />
        </Tooltip>
      </ActionWrapper>
    ),
  },
];

export default columns;
