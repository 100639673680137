import {
  post, get, delete as axiosDelete, put as axiosPut,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../components/Notification';
import { getErrorMessage } from '../../shared/function';

const ON_GET_BLOGS = 'ON_GET_BLOGS';
const ON_CREATE_BLOG = 'ON_CREATE_BLOG';
const ON_EDIT_BLOG = 'ON_EDIT_BLOG';
const ON_REMOVE_BLOG = 'ON_REMOVE_BLOG';

function* getBlogs({ params, setData }) {
  try {
    const response = yield call(get, '/blogs', { params });

    setData({
      loading: false,
      items: response.items,
      totalPage: response.totalPage,
      currentPage: response.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* createBlog({ params, callback }) {
  try {
    yield call(post, '/blogs', params);

    Notification.success({ message: 'Media has been created Successfully' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* editBlog({ params, callback }) {
  try {
    yield call(axiosPut, `/blogs/${params.id}`, params.formData);

    Notification.success({ message: 'Media has been edited successfully.' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* removeBlog({ params, callback }) {
  try {
    yield call(axiosDelete, `/blogs/${params.id}`);
    Notification.success({ message: 'Media has been deleted' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onGetBlogs = (params, setData) => ({
  type: ON_GET_BLOGS, params, setData,
});

export const onNewBlog = (params, callback) => ({
  type: ON_CREATE_BLOG, params, callback,
});

export const onEditBlog = (params, callback) => ({
  type: ON_EDIT_BLOG, params, callback,
});

export const onDeleteBlog = (params, callback) => ({
  type: ON_REMOVE_BLOG, params, callback,
});

export default function* blogsWatcher() {
  yield takeLatest(ON_GET_BLOGS, getBlogs);
  yield takeLatest(ON_REMOVE_BLOG, removeBlog);
  yield takeLatest(ON_CREATE_BLOG, createBlog);
  yield takeLatest(ON_EDIT_BLOG, editBlog);
}
