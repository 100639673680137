import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tooltip, Tag } from 'antd';
import { ActionWrapper } from '../styles';
import { fallbackImage } from '../../shared/function';

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    defaultSortOrder: 'descend',
  },
  {
    width: 400,
    title: 'Image',
    dataIndex: 'image',
    render: (image) => <img src={image || fallbackImage} style={{ width: 100, height: 117 }} alt='error' />,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: {},
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: {},
    render: (status) => {
      switch (status) {
        case 'draft':
          return <Tag color='red'>Draft</Tag>;
        case 'publish':
          return <Tag color='green'>Publish</Tag>;
        default:
          return '';
      }
    },
  },
  {
    title: 'Create Date',
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: {},
  },
  {
    title: 'Action',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Tooltip title='Edit' color='geekblue' key='geekblue'>
          <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onEdit(item)} />
        </Tooltip>
        <Tooltip title='Delete' color='red' key='red'>
          <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onDelete(item)} />
        </Tooltip>
      </ActionWrapper>
    ),
  },
];

export default columns;
