export const ON_AUTHENTICATION = 'ON_AUTHENTICATION';
export const ON_UPDATE_AUTHENTICATION = 'ON_UPDATE_AUTHENTICATION';

const INITIAL_STATE = {
  authetication: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ON_UPDATE_AUTHENTICATION:
      return { authetication: action.payload.authentication };
    default:
      return state;
  }
};

export const onAuthentication = (callback) => ({ type: ON_AUTHENTICATION, callback });

export const onUpdateAuthtication = (value) => ({
  type: ON_UPDATE_AUTHENTICATION,
  payload: {
    authentication: value,
  },
});
