import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import {
  Row, Col, Input, Form, Button,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';

const Filter = ({ currentFilter, changeLocation }) => {
  const pathLocation = useLocation();
  const [form] = Form.useForm();
  let typing = null;

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      changeLocation();
    }, 1000);
  };

  const clearFilter = () => {
    currentFilter.current = {};
    form.setFieldsValue({
      keyword: '',
    });
    changeLocation();
  };

  useEffect(() => {
    const {
      keyword,
    } = queryString.parse(pathLocation.search);

    form.setFieldsValue({
      keyword: keyword || '',
    });
  }, []);

  return (
    <Form layout='vertical' form={form} style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col span={24} md={8} lg={6} xl={6} xxl={6}>
          <Form.Item
            name='keyword'
            label='Search Keyword'
          >
            <Input placeholder='Input keyword...' onChange={onSearch} />
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={6} xl={6} xxl={6}>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type='primary' onClick={clearFilter} icon={<FilterOutlined />}>Clear Filter</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
