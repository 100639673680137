import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fieldValidate,
} from '../../../shared/function';
import Upload from '../../../components/Upload';
import { onEditPortfolio } from '../saga';

const { Option } = Select;

const Edit = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(
    onEditPortfolio(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    const formData = new FormData();
    formData.append('portfolio[name]', values.name);
    formData.append('portfolio[short_description]', values.short_description);
    formData.append('portfolio[location]', values.location);
    formData.append('portfolio[link]', values.link);
    formData.append('portfolio[portfolio_type]', values.portfolio_type);
    if (values.info && values.info.length > 0) {
      values.info.map((info) => {
        formData.append('portfolio[info][]', info);
      });
    }
    if (values.image) formData.append('portfolio[image]', values.image);
    editData({ id: item.id, formData });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        name: item.name,
        short_description: item.shortDescription,
        location: item.location,
        link: item.link,
        portfolio_type: item.portfolioType,
        info: item.info,
      });
      setState({ status: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      width={992}
      title={`Edit Portfolio: ${item.name || ''}`}
    >
      <Form
        layout='vertical'
        form={form}
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              label='Image'
              name='image'
            >
              <Upload
                defaultValue={item.image}
                cropImage
                cropImageProps={{
                  rotate: true, aspect: 398 / 149,
                }}
                onFileChange={(file) => form.setFieldsValue({ image: file })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='name'
              label='Name'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Name' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='link'
              label='Link Website'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Link Website' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='short_description'
              label='Short Description'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Short Description' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[fieldValidate.required]}
              name='portfolio_type'
              label='Type'
            >
              <Select placeholder='Select a option'>
                <Option value='current'>Current</Option>
                <Option value='past'>Past</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[fieldValidate.required]}
              name='location'
              label='Location'
            >
              <Select placeholder='Select a option'>
                <Option value='indonesia'>Indonesia</Option>
                <Option value='singapore'>Singapore</Option>
                <Option value='thailand'>Thailand</Option>
                <Option value='vietnam'>Vietnam</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='info'
              label='Info'
            >
              <Select placeholder='Add new' mode='tags' />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Save
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Close
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
