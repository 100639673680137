import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #090513;
    -webkit-box-shadow: 0 0 0px 1000px #F8F6FB inset !important;
  }

  input,
  textarea,
  button,
  select,
  a{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  body {
    color: #090513;
    background: #F8F6FB;
  }

  .editor {
    display: inherit;
  }

  .editorHide {
    display: none;
  }

  .bold {
    font-weight: bold;
  }

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }

  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .site-statistic-card {
    background: #f0f2f5;
  }

  .ant-layout.ant-layout-has-sider>.ant-layout, .ant-layout.ant-layout-has-sider>.ant-layout-content {
    overflow-x: hidden;
  }

  .site-layout .site-layout-background {
    background: #fff;
    padding: 0 20px;
  }

  .ant-modal-root {
    background: transparent !important;
  }

  .rdw-editor-wrapper {
    border: 1px solid #F1F1F1;
    color: black;
  }

  .rdw-editor-main {
    padding: 10px;
    max-height: 500px;
    overflow: auto;
  }

  .rdw-custom-color-picker {
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }

  .block-picker {
    z-index: 9;
  }

  .custom-collapse {
    position: relative;
  }

  .rdw-dropdown-wrapper{
    a {
      color: black;
    }
  }

  .ant-table-wrapper {
    width: 100% !important;
  }

  #root {
    display: flex;
    height: 100%;
    background: transparent !important;
  }

  ::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar
  {
    width: 8px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    background: black;
  }

  @media (max-width: 767px) {
    ::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }
  }

  .custom-spin {
    width: 100%;

    .ant-spin-container {
      width: 100%;
    }
  }

  .site-tag-plus {
    background: #fff;
    border-style: dashed;
  }
  .edit-tag {
    user-select: none;
  }
  .tag-input {
    width: 78px;
    margin-right: 8px;
    vertical-align: top;
  }
`;
