import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fieldValidate,
} from '../../../shared/function';
import Upload from '../../../components/Upload';
import { onNewOurLeader } from '../saga';

const Create = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [isShow, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setShowModal(false);
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmiting(false);
  };

  const createData = useCallback((params) => dispatch(
    onNewOurLeader(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);

    const formData = new FormData();
    formData.append('our_leader[name]', values.name);
    formData.append('our_leader[description]', values.description);
    formData.append('our_leader[position]', values.position);

    if (values.image) formData.append('our_leader[image]', values.image);
    createData(formData);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    },
  }));

  return (
    <Modal
      closable={false}
      visible={isShow}
      footer={null}
      width={992}
      title='Create Leader Info'
    >
      <Form
        layout='vertical'
        form={form}
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              label='Image'
              name='image'
              rules={[
                fieldValidate.required,
              ]}
            >
              <Upload
                cropImage
                cropImageProps={{
                  rotate: true, aspect: 13/14,
                }}
                onFileChange={(file) => form.setFieldsValue({ image: file })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='position'
              label='Position'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Position' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='name'
              label='Name'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Name' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='description'
              label='Description'
            >
              <Input.TextArea placeholder='Description' rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Save
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Close
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
