import React, { useState } from 'react';
import { Layout } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';

const { Header } = Layout;

const PrivateLayout = ({ siderRef }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    siderRef.current.toggle();
    setCollapsed(!collapsed);
  };

  return (
    <Header className='site-layout-background'>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: toggle,
      })}
    </Header>
  );
};

export default PrivateLayout;
