import {
  post, get, delete as axiosDelete, put as axiosPut,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../components/Notification';
import { getErrorMessage } from '../../shared/function';

const ON_GET_PORTFOLIOS = 'ON_GET_PORTFOLIOS';
const ON_CREATE_PORTFOLIO = 'ON_CREATE_PORTFOLIO';
const ON_EDIT_PORTFOLIO = 'ON_EDIT_PORTFOLIO';
const ON_REMOVE_PORTFOLIO = 'ON_REMOVE_PORTFOLIO';

function* getPortfolios({ params, setData }) {
  try {
    const response = yield call(get, '/portfolios', { params });

    setData({
      loading: false,
      items: response.items,
      totalPage: response.totalPage,
      currentPage: response.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* createPortfolio({ params, callback }) {
  try {
    yield call(post, '/portfolios', params);

    Notification.success({ message: 'Portfolio has been created Successfully' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* editPortfolio({ params, callback }) {
  try {
    yield call(axiosPut, `/portfolios/${params.id}`, params.formData);

    Notification.success({ message: 'Portfolio has been edited successfully.' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* removePortfolio({ params, callback }) {
  try {
    yield call(axiosDelete, `/portfolios/${params.id}`);
    Notification.success({ message: 'Portfolio has been deleted' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onGetPortfolios = (params, setData) => ({
  type: ON_GET_PORTFOLIOS, params, setData,
});

export const onNewPortfolio = (params, callback) => ({
  type: ON_CREATE_PORTFOLIO, params, callback,
});

export const onEditPortfolio = (params, callback) => ({
  type: ON_EDIT_PORTFOLIO, params, callback,
});

export const onDeletePortfolio = (params, callback) => ({
  type: ON_REMOVE_PORTFOLIO, params, callback,
});

export default function* portfolioWatcher() {
  yield takeLatest(ON_GET_PORTFOLIOS, getPortfolios);
  yield takeLatest(ON_REMOVE_PORTFOLIO, removePortfolio);
  yield takeLatest(ON_CREATE_PORTFOLIO, createPortfolio);
  yield takeLatest(ON_EDIT_PORTFOLIO, editPortfolio);
}
