import React, { useState, useImperativeHandle, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
  AuditOutlined, LogoutOutlined, ContactsOutlined, AppstoreOutlined, DiffOutlined, BorderOuterOutlined, RadarChartOutlined,
} from '@ant-design/icons';
import { BranchName } from '../styles';

const { Sider } = Layout;
const { SubMenu } = Menu;
const leftNav = [
  {
    text: 'Portfolio',
    icon: <AuditOutlined style={{ fontSize: 20 }} />,
    key: '/portfolio',
  },
  {
    text: 'Media',
    icon: <AppstoreOutlined style={{ fontSize: 20 }} />,
    key: '/media',
  },
  {
    text: 'Press',
    icon: <DiffOutlined style={{ fontSize: 20 }} />,
    key: '/presses',
  },
  {
    text: 'Contact Info',
    icon: <ContactsOutlined style={{ fontSize: 20 }} />,
    key: '/contact-us',
  },
];

const aboutUsPagePath = [
  '/our-leaders',
  '/our-values',
  '/our-competencies',
  '/our-firm',
  '/our-impacts',
];

const CustomSider = React.forwardRef(({}, ref) => {
  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname);
  const [collapsed, setCollapsed] = useState(false);

  useImperativeHandle(ref, () => ({
    toggle: () => setCollapsed(!collapsed),
  }));

  const openSubMenu = () => {
    if (aboutUsPagePath.includes(location.pathname)) return 'about-us';

    return null;
  };

  useEffect(() => {
    setSelected(location.pathname);
  }, [location.pathname]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <BranchName strong>{ collapsed ? 'RS' : 'Risa Partners'}</BranchName>
      <Menu
        theme='dark'
        mode='inline'
        selectedKeys={[selected, openSubMenu()]}
        defaultOpenKeys={[openSubMenu()]}
        defaultSelectedKeys={[selected, openSubMenu()]}
      >
        {leftNav.map((item) => (
          <Menu.Item key={item.key} icon={item.icon}>
            <Link to={item.key}>
              {item.text}
            </Link>
          </Menu.Item>
        ))}
        <SubMenu
          key='about-us'
          icon={<BorderOuterOutlined style={{ fontSize: 20 }} />}
          title={(
            <span>
              About Us Page
            </span>
          )}
        >
          <Menu.Item key='/our-firm'>
            <Link to='/our-firm'>
              Our Firm
            </Link>
          </Menu.Item>
          <Menu.Item key='/our-leaders'>
            <Link to='/our-leaders'>
              Our Leaders
            </Link>
          </Menu.Item>
          <Menu.Item key='/our-values'>
            <Link to='/our-values'>
              Our Values
            </Link>
          </Menu.Item>
          <Menu.Item key='/our-competencies'>
            <Link to='/our-competencies'>
              Our Competencies
            </Link>
          </Menu.Item>
          <Menu.Item key='/our-impacts'>
            <Link to='/our-impacts'>
              Our Impacts
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key='/our-business' icon={<RadarChartOutlined />}>
          <Link to='/our-business'>
            Our Business Page
          </Link>
        </Menu.Item>
        <Menu.Item key='/home-page-content' icon={<RadarChartOutlined />}>
          <Link to='/home-page-content'>
            Home Page Content
          </Link>
        </Menu.Item>
        <Menu.Item key='/user/sign_in' icon={<LogoutOutlined />}>
          <Link to='/user/sign_in'>
            Logout
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
});

export default CustomSider;
