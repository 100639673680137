import React, {
  useState, useEffect, useCallback,
} from 'react';
import {
  Row, Col, Tabs, Button, Form, Typography,
} from 'antd';
import { useDispatch } from 'react-redux';
import Ckeditor from '../../../shared/Ckeditor';
import { onGetPageContent, onUpdatePageContent } from '../saga';

const { TabPane } = Tabs;
const { Title } = Typography;

const Page = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({});

  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const callback = (status, response) => {
    if (status === 200) {
      setState(response);
    }

    setSubmiting(false);
  };

  const getData = useCallback((params) => dispatch(
    onGetPageContent(params, setState),
  ), [dispatch]);

  const updateData = useCallback((params) => dispatch(
    onUpdatePageContent(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    const params = [{
      key: 'private_equity',
      value: values.private_equity || state.private_equity,
    }, {
      key: 'real_assets',
      value: values.real_assets || state.real_assets,
    }, {
      key: 'venture_capital',
      value: values.venture_capital || state.venture_capital,
    }, {
      key: 'private_debt',
      value: values.private_debt || state.private_debt,
    }];

    updateData({ page_content: { content: params }, id: state.id });
  };

  useEffect(() => {
    if (state.id) {
      form.setFieldsValue({
        private_equity: state.private_equity,
        real_assets: state.real_assets,
        venture_capital: state.venture_capital,
        private_debt: state.private_debt,
      });
    }
  }, [state]);

  useEffect(() => {
    getData({ page: 'our_business' });
  }, []);

  return (
    <Form
      layout='vertical'
      form={form}
      scrollToFirstError
      onFinish={onFinish}
    >
      <Row>
        <Col offset={4} span={16} style={{ marginBottom: 20 }}>
          <Title level={2} style={{ textAlign: 'center' }}>Our Business Page</Title>
        </Col>
        <Col offset={4} span={16} style={{ marginBottom: 20 }}>
          <Tabs defaultActiveKey='1' onChange={callback}>
            <TabPane tab='Private Equity' key='1'>
              <Form.Item
                name='private_equity'
              >
                <Ckeditor
                  onChange={(value) => {
                    form.setFieldsValue({ private_equity: value });
                  }}
                  defaultValue={ state.private_equity || ''}
                />
              </Form.Item>
            </TabPane>
            <TabPane tab='Real Assets' key='2'>
              <Form.Item
                name='real_assets'
              >
                <Ckeditor
                  onChange={(value) => {
                    form.setFieldsValue({ real_assets: value });
                  }}
                  defaultValue={ state.real_assets || ''}
                />
              </Form.Item>
            </TabPane>
            <TabPane tab='Venture Capital' key='3'>
              <Form.Item
                name='venture_capital'
              >
                <Ckeditor
                  onChange={(value) => {
                    form.setFieldsValue({ venture_capital: value });
                  }}
                  defaultValue={ state.venture_capital || ''}
                />
              </Form.Item>
            </TabPane>
            <TabPane tab='Private Debt' key='4'>
              <Form.Item
                name='private_debt'
              >
                <Ckeditor
                  onChange={(value) => {
                    form.setFieldsValue({ private_debt: value });
                  }}
                  defaultValue={ state.private_debt || ''}
                />
              </Form.Item>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Row justify='center'>
        <Button
          disabled={submiting}
          type='primary'
          size='large'
          htmlType='submit'
          style={{ width: 150, marginRight: 20 }}
        >
          Save
        </Button>
      </Row>
    </Form>
  );
};

export default Page;
