import {
  post, get, delete as axiosDelete, put as axiosPut,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../components/Notification';
import { getErrorMessage } from '../../shared/function';

const ON_GET_OUR_LEADERS = 'ON_GET_OUR_LEADERS';
const ON_CREATE_OUR_LEADER = 'ON_CREATE_OUR_LEADER';
const ON_EDIT_OUR_LEADER = 'ON_EDIT_OUR_LEADER';
const ON_REMOVE_OUR_LEADER = 'ON_REMOVE_OUR_LEADER';

function* getOurLeaders({ params, setData }) {
  try {
    const response = yield call(get, '/our_leaders', { params });

    setData({
      loading: false,
      items: response.items,
      totalPage: response.totalPage,
      currentPage: response.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* createOurLeader({ params, callback }) {
  try {
    yield call(post, '/our_leaders', params);

    Notification.success({ message: 'Leader Info has been created Successfully' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* editOurLeader({ params, callback }) {
  try {
    yield call(axiosPut, `/our_leaders/${params.id}`, params.formData);

    Notification.success({ message: 'Leader Info has been edited successfully.' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* removeOurLeader({ params, callback }) {
  try {
    yield call(axiosDelete, `/our_leaders/${params.id}`);
    Notification.success({ message: 'Leader Info has been deleted' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onGetOurLeaders = (params, setData) => ({
  type: ON_GET_OUR_LEADERS, params, setData,
});

export const onNewOurLeader = (params, callback) => ({
  type: ON_CREATE_OUR_LEADER, params, callback,
});

export const onEditOurLeader = (params, callback) => ({
  type: ON_EDIT_OUR_LEADER, params, callback,
});

export const onDeleteOurLeader = (params, callback) => ({
  type: ON_REMOVE_OUR_LEADER, params, callback,
});

export default function* portfolioWatcher() {
  yield takeLatest(ON_GET_OUR_LEADERS, getOurLeaders);
  yield takeLatest(ON_REMOVE_OUR_LEADER, removeOurLeader);
  yield takeLatest(ON_CREATE_OUR_LEADER, createOurLeader);
  yield takeLatest(ON_EDIT_OUR_LEADER, editOurLeader);
}
