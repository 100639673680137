import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fieldValidate,
} from '../../../shared/function';
import Upload from '../../../components/Upload';
import { onEditOurLeader } from '../saga';

const { Option } = Select;

const Edit = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(
    onEditOurLeader(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    const formData = new FormData();
    formData.append('our_leader[name]', values.name);
    formData.append('our_leader[description]', values.description);
    formData.append('our_leader[position]', values.position);

    if (values.image) formData.append('our_leader[image]', values.image);
    editData({ id: item.id, formData });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        name: item.name,
        description: item.description,
        position: item.position,
      });
      setState({ status: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      width={992}
      title={`Edit Leader Info: ${item.name || ''}`}
    >
      <Form
        layout='vertical'
        form={form}
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              label='Image'
              name='image'
            >
              <Upload
                defaultValue={item.image}
                cropImage
                cropImageProps={{
                  rotate: true, aspect: 13/14,
                }}
                onFileChange={(file) => form.setFieldsValue({ image: file })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='position'
              label='Position'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Position' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='name'
              label='Name'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Name' />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name='description'
              label='Description'
            >
              <Input.TextArea placeholder='Description' rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Save
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Close
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
