import {
  post, get, delete as axiosDelete, put as axiosPut,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../components/Notification';
import { getErrorMessage } from '../../shared/function';

const ON_GET_PRESSES = 'ON_GET_PRESSES';
const ON_CREATE_PRESS = 'ON_CREATE_PRESS';
const ON_EDIT_PRESS = 'ON_EDIT_PRESS';
const ON_REMOVE_PRESS = 'ON_REMOVE_PRESS';

function* getPresses({ params, setData }) {
  try {
    const response = yield call(get, '/presses', { params });

    setData({
      loading: false,
      items: response.items,
      totalPage: response.totalPage,
      currentPage: response.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* createPress({ params, callback }) {
  try {
    yield call(post, '/presses', params);

    Notification.success({ message: 'Press has been created Successfully' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* editPress({ params, callback }) {
  try {
    yield call(axiosPut, `/presses/${params.id}`, params.formData);

    Notification.success({ message: 'Press has been edited successfully.' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* removePress({ params, callback }) {
  try {
    yield call(axiosDelete, `/presses/${params.id}`);
    Notification.success({ message: 'Press has been deleted' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onGetPresses = (params, setData) => ({
  type: ON_GET_PRESSES, params, setData,
});

export const onNewPress = (params, callback) => ({
  type: ON_CREATE_PRESS, params, callback,
});

export const onEditPress = (params, callback) => ({
  type: ON_EDIT_PRESS, params, callback,
});

export const onDeletePress = (params, callback) => ({
  type: ON_REMOVE_PRESS, params, callback,
});

export default function* pressWatcher() {
  yield takeLatest(ON_GET_PRESSES, getPresses);
  yield takeLatest(ON_REMOVE_PRESS, removePress);
  yield takeLatest(ON_CREATE_PRESS, createPress);
  yield takeLatest(ON_EDIT_PRESS, editPress);
}
