import {
  get, put as axiosPut,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../components/Notification';
import { getErrorMessage } from '../../shared/function';

const ON_GET_PAGE_CONTENT = 'ON_GET_PAGE_CONTENT';
const ON_UPDATTE_PAGE_CONTENT = 'ON_UPDATTE_PAGE_CONTENT';

function* getPageContent({ params, setData }) {
  try {
    const response = yield call(get, '/page_contents/data', { params });

    setData(response);
  } catch (error) {
    console.log(error);
  }
}

function* updatePageContent({ params, callback }) {
  try {
    const response = yield call(axiosPut, `/page_contents/${params.id}`, params);

    Notification.success({ message: 'Content has been edited successfully.' });
    callback(200, response);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

export const onGetPageContent = (params, setData) => ({
  type: ON_GET_PAGE_CONTENT, params, setData,
});

export const onUpdatePageContent = (params, callback) => ({
  type: ON_UPDATTE_PAGE_CONTENT, params, callback,
});

export default function* pageWatcher() {
  yield takeLatest(ON_GET_PAGE_CONTENT, getPageContent);
  yield takeLatest(ON_UPDATTE_PAGE_CONTENT, updatePageContent);
}
