import styled from 'styled-components';
import { Result } from 'antd';

export default styled(Result)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ant-result-image {
    margin: 0;
  }
`;
