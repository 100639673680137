import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { onLogout } from './saga';

export default function PublicRoute({ component: Component, ...rest }) {
  const dispatch = useDispatch();

  const logout = useCallback(
    () => dispatch(onLogout()),
    [dispatch],
  );

  useEffect(() => {
    logout();
  }, []);

  return <Route {...rest} render={() => <Component />} />;
}

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};
